<template>
  <div class="login">
    <div class="from-box">
      <h2>用户登录</h2>
      <el-form
        :model="loginFrom"
        :rules="rules"
        ref="loginRuleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号" prop="cellPhoneNumber">
          <el-input
            v-model="loginFrom.cellPhoneNumber"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            v-model="loginFrom.password"
            type="password"
            placeholder="请输入密码"
            @keyup.enter.native="submitForm('loginRuleForm', loginFrom)"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('loginRuleForm', loginFrom)"
            >登入</el-button
          >
        </el-form-item>
      </el-form>
      <div class="registerTips">
        还没有账号？立即<a @click="loginHand">注册</a>
      </div>
    </div>
  </div>
</template>
<script>
import Mock from "mockjs";
export default {
  data() {
    //手机号正则校验
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入手机号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("请输入正确的手机号"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入手机号"));
        }
      }
    };
    return {
      loginFrom: {
        cellPhoneNumber: "",
        password: "",
      },
      rules: {
        cellPhoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: isMobileNumber, trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    onpopstate = () => {
      //后退按钮触发事件
      let type = sessionStorage.getItem("type");
      sessionStorage.setItem("type", type === "1" ? "0" : "1");
    };
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    submitForm(loginRuleForm, data) {
      this.$refs[loginRuleForm].validate((valid) => {
        if (valid) {
          const listData = JSON.parse(localStorage.getItem("data"))
            ? JSON.parse(localStorage.getItem("data"))
            : "";
          let loginList = listData.filter(
            (item) =>
              item.cellPhoneNumber === data.cellPhoneNumber &&
              item.pass === data.password
          );
          localStorage.setItem("currentUserName", JSON.stringify(loginList[0]));
          if (loginList.length > 0) {
            this.$router.push("/home/workplaceInformation");
            this.$message({
              message: "登入成功",
              type: "success",
            });
            const token = Mock.mock("@word(18)");
            localStorage.setItem("token", token);
            // const timer = setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            // this.$once("hook:beforeDestroy", () => {
            //   setTimeout(timer);
            // });
          } else {
            this.$message.error("账号或密码错误！");
          }
        } else {
          return false;
        }
      });
    },
    //
    loginHand() {
      this.$router.push("/register");
    },
  },
};
</script>


<style lang="scss" scoped>
.login {
  max-width: 1920px;
  min-height: 904px;
  background: #f5f7fa;
  display: flex;
  justify-content: center;
  padding-top: 31px;
}
.from-box {
  width: 489px;
  height: 408px;
  background: #ffffff;
}
h2 {
  margin-bottom: 36px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #302e37;
  line-height: 25px;
  margin-top: 45px;
}
::v-deep.demo-ruleForm {
  width: 400px;
}
::v-deep .el-input {
  width: 350px;
  height: 50px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(48, 46, 55, 0.1);
}
::v-deep .el-input__inner {
  width: 350px;
  height: 50px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(48, 46, 55, 0.1);
}
.el-button {
  width: 350px;
  height: 50px;
  background: #ff7d3e;
  border-radius: 2px;
  border: 1px solid #ff7d3e;
}
.registerTips {
  text-align: right;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #302e37;
  line-height: 20px;
  a {
    color: #ff7d3e;
    text-decoration: none;
    margin-right: 40px;
  }
}
</style>